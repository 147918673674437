<template>
  <Page class="page-wrap">
    <div class="btn-area">
      <IconButton :icon="addIcon" type="primary">新建</IconButton>
      <IconButton :icon="importFileIcon">导入模板</IconButton>
      <IconButton :icon="uploadIcon">导入</IconButton>
      <IconButton :icon="exportIcon">导出</IconButton>
      <IconButton :icon="qrcodeDownloadIcon">批量下载二维码</IconButton>
    </div>
    <div class="table-area" ref="containerRef" >
      <div>
        <el-table  v-loading="loading" :height="maxHeight" :data="tableData" style="width: 100%">
          <el-table-column fixed="left" width="22">
            <template #header>
              <div class="checkbox-wrap">
                <el-checkbox
                    v-model="allCheckedStatus"
                    :indeterminate="indeterminate"
                    @change="(e) => selectAll(e, 'all')"
                ></el-checkbox>
              </div>

            </template>
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0"></div>
                <div v-else class="checkbox-wrap">
                  <el-checkbox
                      v-model="checkListMap[row.id]"
                      @change="(e) => selectRow(e, row.id)"
                  ></el-checkbox>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="资产类型" fixed="left" width="140">
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0">
                  <el-select v-model="form.value" size="small">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else>
                  {{ row.amount1 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount3" width="140" label="资产编码" fixed="left">
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0">
                  <el-input
                      size="small"
                      placeholder="请输入内容"
                      v-model="form.value">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                  </el-input>
                </div>
                <div v-else class="text">
                  {{ row.amount1 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount2" width="140" label="状态" fixed="left">
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0">
                  <el-select size="small" v-model="form.value">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <span v-else class="status" :class="{ 'status-green': $index %2 === 0, 'status-blue': $index %2 !== 0 }">
                  {{ row.amount1 }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount3" width="140" label="资产名称" fixed="left">
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0">
                  <el-input
                      size="small"
                      placeholder="请输入内容"
                      v-model="form.value">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                  </el-input>
                </div>
                <div v-else>
                  {{ row.amount1 }}
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="amount4" label="旧资产编码" width="240">
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0">
                  <el-input
                      size="small"
                      placeholder="请输入内容"
                      v-model="form.value">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                  </el-input>
                </div>
                <div v-else>
                  {{ row.amount1 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount5" label="资产所属公司" width="240">
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0">
                  <el-select v-model="form.value" size="small">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else>
                  {{ row.amount1 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount6" label="资产所属部门" width="240">
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0">
                  <el-select v-model="form.value" size="small">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else>
                  {{ row.amount1 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount7" label="核算主体" width="240">
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0">
                  <el-select v-model="form.value" size="small">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else>
                  {{ row.amount1 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount8" label="资产第一责任人" width="240">
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0">
                  <el-select v-model="form.value" size="small">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else>
                  {{ row.amount1 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount9" label="所属项目" width="240">
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0">
                  <el-select v-model="form.value" size="small">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else>
                  {{ row.amount1 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount10" label="实际位置" width="240">
            <template #default="{ row, column, $index }">
              <div>
                <div v-if="$index === 0">
                  <el-select v-model="form.value" size="small">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else>
                  {{ row.amount1 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount11" label="投运日期" width="240">
          </el-table-column>
          <el-table-column prop="amount12" label="更新时间" width="240">
          </el-table-column>
          <el-table-column prop="amount14" fixed="right" label="操作" width="216">
            <template #default="{ row, column, $index }">
            <div class="operator" v-if="$index > 0">
              <el-link class="text" :underline="false">查看详情</el-link>
              <el-link class="text" :underline="false">下载二维码</el-link>
              <el-link class="text text-danger"  :underline="false">删除</el-link>
            </div>
            </template>
          </el-table-column>
        </el-table>
<!--        <div class="empty">empty</div>-->
      </div>
      <div ref="paginationRef" class="pagination-wrap">
        <Pagination
            style="padding-bottom: 0"
            layout="prev, pager, next, jumper,  total, sizes"
            :background="false"
            :total="pageInfo.total"
            :page.sync="pageInfo.current"
            :limit.sync="pageInfo.rowCount"
            @pagination="currentChange"
        />
      </div>
    </div>

  </Page>
</template>

<script>
import Page from "@/components/Page/index.vue";
import IconButton from "@/views/ruge/ams/components/IconButton/index.vue";
import addIcon from "@/assets/images/ams/add.svg";
import exportIcon from "@/assets/images/ams/export.svg";
import importFileIcon from "@/assets/images/ams/import-file.svg";
import qrcodeDownloadIcon from "@/assets/images/ams/qrcode-download.svg";
import uploadIcon from "@/assets/images/ams/upload.svg";
import Template from "@/views/ruge/modulx/template.vue";
import Pagination from "@/components/Pagination/index.vue";
import {debounce} from "lodash";


export default {
  components: {Pagination, Template, IconButton, Page },
  data() {
    return {
      addIcon,
      exportIcon,
      importFileIcon,
      qrcodeDownloadIcon,
      uploadIcon,
      checkList: [],
      checkListMap: {},
      allCheckedStatus: false,
      indeterminate: false,
      pageInfo: {
        total: 4,
        current: 1,
        rowCount: 10,
      },
      form: {
        value: "",
      },
      options: [
        {
          value: "ascending",
          label: "升序",
        },
        {
          value: "descending",
          label: "降序",
        },
      ],
      tableData: [
        {
          id: "12987122",
          name: "王小虎",
          amount1: "234",
          amount2: "3.2",
          amount3: 10,
        },
        {
          id: "12987123",
          name: "王小虎",
          amount1: "165",
          amount2: "4.43",
          amount3: 12,
        },
        {
          id: "12987124",
          name: "王小虎",
          amount1: "324",
          amount2: "1.9",
          amount3: 9,
        },
        {
          id: "12987125",
          name: "王小虎",
          amount1: "621",
          amount2: "2.2",
          amount3: 17,
        },
        {
          id: "12987126",
          name: "王小虎",
          amount1: "539",
          amount2: "4.1",
          amount3: 15,
        },
      ],
      maxHeight: 400,
      loading: false,
      debouncedHandler: () => {},
      debounceResizeFn:() => {},
    };
  },
  created() {
    this.debouncedHandler = debounce((v) => this.fetchListFn(v), 200);
  },
  mounted() {
    this.debouncedHandler(this.form);

    this.debounceResizeFn = debounce(() => this.updateTableMaxHeight(), 200);
    window.addEventListener("resize",  this.debounceResizeFn);
    this.debounceResizeFn();

  },

  methods: {
    currentChange(event) {
      this.pageInfo = {
        ...this.pageInfo,
        current: event.page,
        rowCount: event.limit,
      };
    },
    getSelectedTable(checked) {
      const [row, ...tableData] = this.tableData
      const checkListMap = {};
      tableData.forEach((item) => {
        checkListMap[item.id] = checked;
      });

      return {tableData, checkListMap}

    },
    /**
     * 更新表格数据，第一行必须存在
     * @param data
     */
    setTableData(data = []) {
      this.tableData = [{},...data]
    },
    async fetchListFn() {
      const fake = (len) => {
        return new Promise(resolve => {
          setTimeout(() =>{
            const data = Array.from({length: len}).map(item=>{
              const val = {};
              [1,2,3,4,5, 6].forEach(item=>{
                val[`amount${item}`] =(Math.random() * 10000).toFixed(2)
              })
              return {
                ...val,
                id: item
              }
            })
            resolve(data)
          }, 1200)
        })
      }
      this.loading = true
      const data = await fake(19)
      this.loading = false
      this.setTableData(data)
      this.checkListMap = this.getSelectedTable(false).checkListMap
    },
    selectAll(selection, val) {
      this.checkListMap = this.getSelectedTable(selection).checkListMap
      this.allCheckedStatus = selection;
      console.log(this.checkListMap);
    },
    selectRow(checked, val) {
      this.checkListMap[val] = checked;
    },
    getClientHeight(refDom) {
      if (!refDom) return 0;
      return refDom.clientHeight;
    },
    updateTableMaxHeight() {
      // 获取父容器的高度
      const containerHeight = this.getClientHeight(this.$refs.containerRef);
      const paginationHeight = this.getClientHeight(this.$refs.paginationRef);

      this.maxHeight =
          containerHeight -
          paginationHeight
      console.log(':height="maxHeight"', this.maxHeight)
    }
  },
  watch: {
    checkListMap: {
      handler(val) {
        const checked = Object.values(val).filter((item) => item);
        const checkedLen = checked.length;
        if (!checkedLen) {
          this.indeterminate = false;
          this.allCheckedStatus = false
        } else if (checkedLen === this.tableData.length -1) {
          this.indeterminate = false;
          this.allCheckedStatus = true
        } else {
          this.indeterminate = true;
          this.allCheckedStatus = false
        }
      },
      deep:true
    },
    form: {
      handler: val => {
        this.pageInfo.current = 1
        if (this.debouncedHandler.cancel) {
          this.debouncedHandler.cancel();
        }
        this.debouncedHandler(val);
      },
      deep: true
    }
  },
};
</script>
<style scoped lang="less">
.page-wrap {
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .btn-area {
    display: flex;
    column-gap: 10px;
    margin-bottom: 23px;
  }
  .table-area {
    flex: 1;
    position: relative;
    overflow: hidden;
    .empty {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .text {
    font-size: 14px;
    color: #2A61FF;
  }
  .status {
    border-radius: 4px 4px 4px 4px;
    padding: 2px 8px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
  }
  .status-green {
    background: #F4FEF6;
    color: #07D9B3;
  }
  .status-blue {
    background: #F4F9FE;
    color: #2A61FF;
    cursor: pointer;
  }
  .operator{
    display: flex;
    flex-wrap: nowrap;
    column-gap: 8px;
    .text-danger{
      color: #F35555;
    }
  }
  ::v-deep {
    .checkbox-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pagination-wrap{
      .el-table_1_column_1 .cell{
        padding: 0 !important;
      }
      .el-pagination__total, .el-pagination__sizes{
        float: right;
      }
    }

  }
}
</style>
