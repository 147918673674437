<template>
  <el-button
    v-bind="$attrs"
    v-on="$listeners"
    class="icon-button"
    :class="{ 'icon-button-primary': $attrs.type }"
  >
    <div class="icon-button-inner">
      <img
        v-if="icon"
        :src="icon"
        class="icon-img"
        :class="[imgClass]"
        alt="icon"
      />
      <slot name="icon"></slot>
      <span>
        <slot></slot>
      </span>
    </div>
  </el-button>
</template>
<script>
export default {
  name: "IconButton",
  props: {
    icon: {
      type: String,
      default: "",
    },
    imgClass: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped lang="less">
.icon-button {
  padding: 9px 11px;
  border-radius: 8px 8px 8px 8px;
  background: #ffffff;
  border: 1px solid #b5bece;
  color: #252d3d;
  &:hover {
    border-color: #2a61ff;
    color: #252d3d;
  }
  &-primary {
    color: #ffffff;
    background: #2a61ff;
    border-color: #2a61ff;
    &:hover {
      color: #ffffff;
    }
  }
}
.icon-button-inner {
  display: flex;
  justify-items: center;
  align-items: center;
  column-gap: 4px;
  img {
    width: 14px;
    height: 14px;
  }
}
</style>
