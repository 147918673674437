var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button",
    _vm._g(
      _vm._b(
        {
          staticClass: "icon-button",
          class: { "icon-button-primary": _vm.$attrs.type },
        },
        "el-button",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "icon-button-inner" },
        [
          _vm.icon
            ? _c("img", {
                staticClass: "icon-img",
                class: [_vm.imgClass],
                attrs: { src: _vm.icon, alt: "icon" },
              })
            : _vm._e(),
          _vm._t("icon"),
          _c("span", [_vm._t("default")], 2),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }